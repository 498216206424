

.cardWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cardContainer {
    display: flex;
    min-width: 270px;
    margin: 10px;
    padding: 20px;
    cursor: pointer;
    border-radius: 5px;
    flex-direction: column;
    border: 1px solid #e5e5e5;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}


  .cardContainer:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,.1);
  }
  
 
.cardLogo.green, .cardTitle.green{
    color: #10121b;
}
.cardLogo.pink, .cardTitle.pink{
    color: #10121b;
}
.cardLogo.brown, .cardTitle.brown{
    color: #10121b;
}

.cardContainer.red{
    background-color:  #D27685 ;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}
.cardContainer.orange{
    background-color:  #9E4784;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}
.cardContainer.cream{
    /* background-color:  #20776e; */
    background-color:  #FCF5E5;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}
.cardContainer.green{
    /* background-color:  #20776e; */
    background-color:  #9EC7CB;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}

.cardContainer:hover {
    transform: translate(0, -5px);
    /* background-color: #c16565;
    -webkit-box-shadow: 0px 0px 5px 0px #c16565; */
    /* box-shadow: 0px 0px 5px 0px #c16565; */
}

.cardTitle {
    font-size: 22px;
    font-weight: 400;
    color: white;
    text-align: right;
}


.cardTopLogo {
    margin-bottom: 56px;
    text-align: right;
}

.cardLogo {
    align-items: flex-end;
    color: #10121b;
}

.userCount{
    margin-top: 4px;
    color: #10121b;
    font-weight: 400;
    font-size: 20px;
    align-items: center;
}
.trendingLogo{
    margin-right: 6px;
    font-weight: 300 !important;
    font-size: 30px !important;
}
