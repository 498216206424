.userAddButton {
    background: linear-gradient(to right, #bbd5bc 0%, #ffcccc 100%);
    color: rgb(12, 11, 11);
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    padding: 5px 30px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }



.userAddButton.active, .updateBut.active, .userUpdateIcon1.active{
    transform: scale(0.95);
    box-shadow: 3px 2px 25px 1px rgba(0,0,0,0.25);
}

.image-upload > input
{
    display: none;
}


.userTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.imgCont1 {
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 2px;
    width: 150px;
    cursor: pointer;
    height: 150px;
    border-radius: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: cover;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

}

.searchBarInput{
    border: none;
    font-size: 15px;
    outline: none;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    width: 100% !important;
}
.userUpdateIcon1{
    cursor: pointer;
    margin: 125px 0px 0px 20px;
}

.updateDataBtn{
    background: linear-gradient(to right, #bbd5bc 0%, #ffcccc 100%);
    color: rgb(12, 11, 11);
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    padding: 5px 30px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }