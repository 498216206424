.sidebar{
    background-color: #10121b !important;
 
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft{
    background-color: #10121b !important;

}
.sidebarWrapper{
    padding: 10px;
    margin-top: 40px;
    background-color: #10121b !important;
}
.sidebarMenu{
    margin-bottom: 10px;
}
.sidebarTitle{
    font-size: 15px;
    color:    #302e2e;
}
.sidebarList{
    list-style: none;
    padding: 5px;
}
.sidebarListItem{
    padding: 5px;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    font-weight: 400;
    align-items: center;
    border-radius: 19px 0px;
    color: #fff7f7;
}
  li.sidebarListItem:hover {
    margin-top: 1%;
    background-color: white;
    font-weight: 600;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px 
}




a.link.active .sidebarListItem{
    background-color: #bbd5bc;
    padding-left: 12px;
    margin-top: 3px;
    margin-bottom: 3px;
    font-weight: 600;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px 
    
}

.sidebarIcon{
    margin-right: 15px;
    font-size: 20px !important;
}