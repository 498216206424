.topNavigator{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin-bottom: 10px;
    margin-top: 50px;
    border-radius: 0;
}
.pTag{
    padding: 6px;
    font-weight: 600;
    font-size: 16px;
    margin-left: 14px;
}