

.Icon{
    font-size: 22px !important;
    color: rgb(129, 39, 39) !important;
    cursor: pointer !important;
}

.addIcon{
    font-size: 40px !important;
    color: rgb(151, 174, 182);
    cursor: pointer;
}

  


.unitImg{
    height: 300px;
    width: 330px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    border-radius: 10px;
}

.searchBarInput {
    border: none;
    font-size: 15px;
    outline: none;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 5px;
    width: 100%;
}
.addUnitInput{
  width: 350px;
  padding: 10px 18px;
  margin: 8px 0;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  box-sizing: border-box;
  border: 3px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}
.image-upload > input
{
    display: none;
}
.addUnitInput:focus {
  border: 3px solid #7e7c7c;
}
/* .addUnitInput{
    padding: 15px;
    border-radius: 10px;
    font-size: 20px;
    width: 400px;
    border: 2px solid gray;
}
input:focus{
    border: 2px solid gray;
} */

.MuiInput{
    margin-top: -30px !important;
}
.selectedIMage{
    width: 50px;
    height: 50px;
}