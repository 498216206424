.MuiButtonBase-root {
    color: rgb(92, 87, 87) !important;
}

.sidebarListItem a.link {
    text-decoration: none;
}

::-webkit-scrollbar {
    width: 5px;
    background: white;
}

::-webkit-scrollbar-thumb {
    background: #e6e6e6;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    color: rgb(83, 83, 83) !important;
}

.searchBar {
    color: #2f323b !important
}

.MuiInputBase-root,
.MuiTablePagination-actions {
    margin-top: -15px !important;
}
/* DARK MODE */

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
    outline: none !important;
}

.sidebarTitle{
   color:  #9a9a9a !important;
}
.sidebar {
background-color: #2f323b !important;    
/* #be6464 */
}
.css-1k455el {
    background-color: #2f323b !important;
}

.css-1nnrorg-MuiPaper-root-MuiAppBar-root {
    background: linear-gradient(to right, #ffffff 0%, #ffcccc 100%) !important; 
}
a.link.active .sidebarListItem {
    color: #2f323b !important;  
}
.sidebarListItem:hover{
    color: #2f323b !important;  
}


/* ALL FORM CSS  */
.userDetCont {
    display: flex;
    min-width: 560px;
    margin: 60px 10px 0px 10px;
    padding: 20px;
    border-radius: 5px;
    flex-direction: column;
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.userTitleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.SubmitBtnBox {
    display: flex;
    align-items: center;
}

.detailTitle {
    font-size: 24px;
    font-weight: 600;
}

.userAddButton {
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}

.updateDataBtn {
    margin: 10px auto 0px auto;
    min-width: 100px;
    background-color: #3bb077;
    border: none;
    color: white;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px;
}

.outerMost {
    padding: 20px;
}

.secondBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 10px;
}

.mainBoxx {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.headingDiv {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.imGCont {
   
    padding: 10px;
  
    margin-top: 40px;
}

.myImg {
    object-fit: cover;
    height: 250px;
    border-radius: 10px;
    cursor: pointer;
    min-width: 250px;
    box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
}

.formContainer {
    flex: 3;
    padding: 40px;
}

.formInMain {
    display: grid;
    grid-template-columns: 1.6fr 3fr;
}

.myLabel {
    padding: 5px;
    margin: 10px;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
}

.myInput {
    padding: 5px;
    margin: 10px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 15px;
    outline: none;
    border: none;
    border-bottom: 1px solid #dbb3b3;
}

.smallboxes {
    margin: 10px;
}

.PanelTopBar{
    background: linear-gradient(to right, #bbd5bc 0%, #ffcccc 100%) !important;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px !important;

}

body{
    background-color: #F1EEE9 !important;
}

.loader {
    margin-top: 300px;
}


a{
    text-decoration: none !important;
    font-size: 18px !important;
    color: black !important;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium{
    font-size: 15px !important;
    font-weight: 400 !important;
}
.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium{
    font-size: 15px !important;
    font-weight: 400 !important;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium{
    font-size: 14px !important;
    font-weight: 300 !important;
    background-color: #10121b !important;
    color: white !important;

}

.newBtn{
    background: linear-gradient(to right, #bbd5bc 0%, #ffcccc 100%);
    color: rgb(12, 11, 11);
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    border: none;
    padding: 5px 30px;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }

  .errorMessage {
    font-size: 13px;
    font-weight: 400;
    color: #555555; /* or any other shade of grey you prefer */
    padding: 10px;
    margin: 10px 0;
    background-color: #f8f8f8;
    border: 1px solid #cccccc;
    border-radius: 3px;
  }

  .fileErr{
    font-size: 14px;
    color: red;
    text-align: center;
  }

  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard.MuiFormLabel-colorPrimary.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-standard{
    margin-top: -31px !important;
  }
  .errText {
    font-size: 15px;
    color: red;
    margin-left: 8px;
  }
  